body {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-content {
    display: flex;
    margin-left: 200px;
    padding: 20px;
}
.content {
    flex-grow: 1;
}
.universal-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}


/*LINKE SEITENLEISTE*/
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    max-height: 100vh;
    background-color: #EEEEEE;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.dark-mode .sidebar {
    background-color: #333;
    color: #eee;
} 
.sidebar::-webkit-scrollbar {
    width: 6px;
}
.sidebar::-webkit-scrollbar-track {
    background: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #A91D3A;
    border-radius: 3px;
}
.dark-mode .sidebar::-webkit-scrollbar-thumb {
    background-color: #76ABAE;
    border-radius: 3px;
}
.sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #444444;
}
.dark-mode .sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
}
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
} 
.sidebar ul li {
    margin-bottom: 10px;
    border-radius: 4px;
}
.sidebar ul li a {
    text-align: left;
    text-decoration: none;
    font-size: 15px;
    font-weight:normal;
    color: #151515;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.069s;
}
.dark-mode .sidebar ul li a {
    color: #EEEEEE;
}
.sidebar ul li a:hover {
    background-color: #A91D3A;
    color: #EEEEEE;
    transform: scale(1.11);
    transition: transform 0.3s ease;
} 
.dark-mode .sidebar ul li a:hover {
    background-color: #76ABAE;
    color: #151515;
    transform: scale(1.11);
    transition: transform 0.3s ease;
}
.sidebar li.active {
    background-color: #A91D3A;
    color: #151515 !important;
    transform: scale(1.11);
    transition: transform 0.4s ease;
    background: linear-gradient(45deg, #A91D3A, #FF6F61, #ff8b79);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite;
}
.dark-mode .sidebar li.active {
    background-color: #76ABAE;
    color: #151515 !important;
    transform: scale(1.11);
    transition: transform 0.4s ease;
    background: linear-gradient(45deg, #A1C4FD, #76ABAE, #A1C4FD);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite;
}
@keyframes holo-effect {
    0% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
}
.sidebar ul li.active a{
    color: #151515;
}
.sidebar ul li.active a:hover {
    transform: scale(1);
} 
.dark-mode .sidebar ul li.active a:hover {
    transform: scale(1);
} 

/*EINSTELLUNGEN*/
.laufendes-projekt-seitenleiste {
    position: fixed;
    top: 65px;
    right: 20px;
    z-index: 1000;
}
.animated-icon {
    font-size: 45px;
    border-radius: 100%;
    display: inline-block;
    background: linear-gradient(45deg, #A91D3A, #FF6F61, #ff8b79);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite; 
    color: #EEEEEE;
    transition: transform 0.4s ease;
}
.dark-mode .animated-icon {
    font-size: 45px;
    border-radius: 100%;
    display: inline-block;
    background: linear-gradient(45deg, #A1C4FD, #76ABAE, #A1C4FD);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite; 
    color: #222831;
    transition: transform 0.4s ease;
}
.animated-icon:hover{
    color: #222831;
    transform: scale(1.15);
    transition: transform 0.4s ease;
}
.dark-mode .animated-icon:hover{
    color: #EEEEEE;
    transform: scale(1.15);
    transition: transform 0.4s ease;
}
.top-bar {
    position: fixed;
    top: 0;
    right: 0;
    right: 0px; 
    height: 70px;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.dropdown-wiki {
    user-select: none;
}
.dropdown-wiki-button {
    padding: 5px 15px;
    background: #A91D3A;
    border-radius: 4px;
    font-weight: 500;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}
.dark-mode .dropdown-wiki-button{
    background-color: #EEEEEE;
    color: #222831;
}
.dropdown-wiki-button:hover {
    background-color: #444444;
    transform: scale(1.15);
    transition: transform 0.3s ease;
}
.dark-mode .dropdown-wiki-button:hover{
    background-color: #76ABAE;
    color: #EEEEEE;
}
.dropdown-wiki-Inhalt {
    position: absolute;
    right: 0px;
    border-radius: 4px;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
    z-index: 1000;
    background-color: rgb(169, 29, 58, 0.8);
    width: 100vw;
}
.dark-mode .dropdown-wiki-Inhalt{
    background-color: rgb(49, 54, 63, 0.8);
}
.dropdown-wiki-items {
    padding: 10px 10px;
    background-color: rgba(238, 238, 238, 0.9);
    transition: background-color 0.3s ease;
    cursor: pointer;
    text-align: right;
    box-sizing: border-box;
    margin-left: 69%;
    margin-top: 5px;
}
.dark-mode .dropdown-wiki-items{
    background-color: rgb(34, 40, 49, 0.9);
    color: #EEEEEE;
}
.dropdown-wiki-items:hover {
    background-color: #444444;
    color: #EEEEEE;
}
.dark-mode .dropdown-wiki-items:hover {
    background-color: #76ABAE;
    color: #151515;
}
.dark-mode-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    transition: transform 0.4s ease;
  }
.dark-mode-button:hover {
    transform: scale(1.3) rotate(22deg);
    transition: transform 0.4s ease;
}
.dark-mode-button svg {
    fill: currentColor;
    transition: fill 0.3s ease;
}
body.dark-mode .dark-mode-button svg {
    fill: #ffffff;
}
body:not(.dark-mode) .dark-mode-button svg {
    fill: #151515;
}
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
}
.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.checkbox input[type="checkbox"] {
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
}
.checkbox-label {
    font-size: 16px;
    color: #151515;
    white-space: nowrap;
}
.dark-mode .checkbox-label {
    color: #ccc;
}
.import-dialog {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;;
}
.dark-mode .import-dialog{
    background-color: #31363F;
    color: #EEEEEE;
}
.import-dialog-title {
    text-align: center;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 2.2rem;
}
.drop-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    margin-bottom: 16px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
} 
.drop-area:hover,
.drop-area.drag-over {
    background-color: #f0f0f0;
    border-color: #A91D3A;
}
.dark-mode .drop-area:hover,
.dark-mode .drop-area.drag-over {
    background-color: #222831;
    border-color: #76ABAE;
}
.upload-icon {
    font-size: 2rem;
    display: block;
    margin-top: 8px;
    transition: font-size 0.3s ease;
}
.drop-area.drag-over .upload-icon {
    font-size: 5rem;
}  
.open-button {
    background-color: #A91D3A;
    color: white;
    border: none;
    padding: 10px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100%;
}
.dark-mode .open-button{
    background-color: #ccc;
    color: #151515;
}
.open-button:hover {
    background-color: #C73659;
}
.dark-mode .open-button:hover {
    background-color: #76ABAE;
}
.folder-icon {
    margin-right: 8px;
}


/*STARTSEITE*/
.card-body {
    padding-top: 1vw;
}
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}
.main-content {
    display: flex;
    margin-left: 250px;
    padding: 20px;
    padding-top: 50px;
    width: calc(100% - 345px);
    box-sizing: border-box;
}
.App {
    width: 100%;
}
.nebeneinander {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2vw;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin-top: 20px;
}
.Auswahl {
    position: relative;
    height: auto;
    min-height: 250px;
    padding: 2rem;
    padding-top: 2rem;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-style: none;
}
.dark-mode .Auswahl {
    background-color: #31363F;
    color: #EEEEEE;
}
.Auswahl:hover {
    background-color: #A91D3A;
    color: #EEEEEE;
    transform: scale(1.07);
}
.dark-mode .Auswahl:hover {
    background-color: #76ABAE;
    color: #151515;
}
.badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}
.badge, .empfohlen {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: bold;
    height: 24px;
    min-width: 24px;
}
.empfohlen {
    color: #151515;
    background: linear-gradient(45deg, #FFD700, #FFA500, #DAA520);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite;
}
.dark-mode .empfohlen {
    color: #31363F;
}
.badge {
    color: #EEEEEE;
}
.dark-mode .badge {
    color: #31363F;
}
.auswahl-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}
.auswahl-title {
    font-size: clamp(1.2rem, 2vw, 1.8rem);
    margin-bottom: 0.5rem;
    width: 100%;
    font-weight: bold;
}
.auswahl-text {
    font-size: clamp(0.9rem, 1.5vw, 1.2rem);
    margin-bottom: 0.5rem;
    width: 100%;
    line-height: 1.4;
}
@media (max-width: 768px) {
    .nebeneinander {
        grid-template-columns: 1fr;
        max-width: 100%;
        margin-left: 0;
        padding: 10px;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .nebeneinander {
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
    }
}

@media (min-width: 1201px) {
    .nebeneinander {
        grid-template-columns: repeat(2, 1fr);
    }
}


/*WIKI*/
.wiki-titel {
    display: flex;
    justify-content: center;
    width: 88%;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 40px;
    color: #A91D3A;
    text-transform: uppercase;
    padding: 10px 0;
    transition: color 0.3s;
}
.dark-mode .wiki-titel{
    color: #76ABAE;
}
.wiki-untertitel {
    display: flex;
    justify-content: left;
    width: 88%;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 25px;
    font-weight: bold;
}
.dark-mode .wiki-untertitel{
    color: #76ABAE;
}
.wiki-text {
    justify-content: left;
    width: 88%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #EEEEEE;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 2em;
    transition: color 0.3s;
}
.dark-mode .wiki-text{
    background-color: #31363F;
    color: #EEEEEE;
}
.wiki-text:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.wiki-text ul {
    list-style-position: outside;
    list-style-type: disc;
}
.wiki-text li::marker {
    color: #A91D3A;
}
.dark-mode .wiki-text li::marker{
    color: #76ABAE;
}
a {
    width: 100%;
    color: #C73659;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    text-decoration: none;
    font-weight: bold;
}
.dark-mode a{
    color: #EEEEEE;
}
a:hover {
    color: #151515;
}
.dark-mode a:hover {
    color: #76ABAE;
}
.wiki-literatur {
    display: flex;
    justify-content: left;
    width: 88%;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 20px;
    font-weight: lighter;
}
.dark-mode .wiki-literatur{
    color: #76ABAE;
}
.wiki-literatur-text {
    justify-content: left;
    width: 88%;
    margin: 0 auto;
    font-size: 11px;
    font-weight: lighter;
    margin-bottom: 2em;
    background: none !important;
}
.dark-mode .wiki-literatur-text{
    background-color: #31363F;
    color: #EEEEEE;
}
.wiki-datenbank {
    display: inline;
    width: 100%;
    padding: 20px;
    margin-left: 82vw;
}
.wiki-text-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2em;
    transition: color 0.3s;
}
.dark-mode .wiki-text-link{
    background-color: #31363F;
}
.wiki-text-link:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.line-break {
    display: block;
    text-indent: -0.67em;
    padding-left: 1em;
}
.wiki-image {
    width: 50em;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.wiki-foto {
    width: 9em;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.img-quelle {
    text-align: center;
    font-size: 11px;
    color: #444444;
    font-weight: lighter;
}
.dark-mode .img-quelle{
    color: #76ABAE;
}
.text-quelle {
    text-align: right;
    padding: 0 !important;
    margin: -12.5px;
    margin-top: 2.5px;
    font-size: 11px;
    color: #444444;
    font-weight: lighter;
}
.dark-mode .text-quelle{
    color: #76ABAE;
}
.wiki-inhaltsverzeichnis{
    display: flex;
    justify-content: left;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 10px;
    font-weight: bold;
}
.wiki-inhaltsverzeichnis a {
    justify-content: left;
    width: 75%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #EEEEEE;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 2em;
    transition: color 0.3s;
}
.wiki-inhaltsverzeichnis ul {
    list-style-position: outside;
    list-style-type: disc;
}
.wiki-inhaltsverzeichnis li::marker {
    color: #A91D3A;
}
.wiki-text-aufbau {
    justify-content: left;
    width: 88%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #EEEEEE;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 2em;
    transition: color 0.3s;
}
.dark-mode .wiki-text-aufbau{
    background-color: #31363F;
    color: #000000;
}
.wiki-text-aufbau:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.wiki-zielgruppe{
    color: #A91D3A;
}
.wiki-kompetenzverb{
    color: #401F71;
}
.wiki-trennung{
    color: #BE7B72;
}
.wiki-tätigkeitsverb{
    color: #824D74;
}
.wiki-klammern{
    font-size: 11px;
}
.wiki-legende {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
.wiki-qualifikationsebene{
    background-color: #FDFFC2;
}
.wiki-handlungsebene{
    background-color: #94FFD8;
}
.wiki-nutzenformulierung{
    background-color: #A3D8FF;
}


/*LERNZIELFORMULATOR*/
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-grow: 1;
}
.main-title {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #151515;
    text-transform: uppercase;
    transition: color 0.3s;
}
.dark-mode .main-title{
    color: #76ABAE;
}
.box-container {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 1.5px 4px rgba(199, 54, 89, 0.9);
    padding: 20px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
}
.dark-mode .box-container{
    background-color: #31363F;
    box-shadow: 0px 1.5px 4px rgb(18, 171, 174, 0.9);
    color: #EEEEEE;
}
.box-container:hover {
    box-shadow: 0 6px 10px rgb(169, 29, 58, 0.9);
}
.dark-mode .box-container:hover{
    box-shadow: 0 6px 10px rgb(18, 171, 174, 0.9);
}
.nutzenformulierung-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.custom-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #444444;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-top: -2px;
}
.dark-mode .custom-checkbox{
    border-color: #222831;
}
.custom-checkbox:checked {
    background-color: #A91D3A;
    border-color: #A91D3A;
}
.dark-mode .custom-checkbox:checked {
    background-color: #EEEEEE;
    border-color: #EEEEEE;
}
.custom-checkbox:checked::before {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 6px;
    height: 13px;
    border: solid #EEEEEE;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}
.dark-mode .custom-checkbox:checked::before {
    border-color: #444444; 
}
.custom-checkbox:hover {
    border-color: #A91D3A;
}
.dark-mode .custom-checkbox:hover {
    border-color: #76ABAE;
}
.custom-checkbox:checked:hover {
    background-color: #444444;
    border-color: #444444;
}
.dark-mode .custom-checkbox:checked:hover {
    background-color: #76ABAE;
    border-color: #76ABAE;
}
.dark-mode .custom-checkbox:checked:hover::before {
    border-color: #eee; 
}
.kognitiv {
    background-color: #C2D9FF !important;
}
.psychomotorisch {
    background-color: #D6EFD8 !important;
}
.affektiv {
    background-color: #f8d7da !important;
}
.inner-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
.freitext {
    position: relative;
    display: inline-block;
    height: 45px;
    min-width: 50px;
    max-width: 100%;
    color: #151515 !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, width 0.3s ease;
    overflow-x: auto;
    overflow-y: hidden;
    resize: none;
    box-sizing: border-box;
    white-space: nowrap;
    width: auto;
}
.dark-mode .freitext{
    background-color: #EEEEEE;
    border: 1px solid #ccc;
}
.freitext::-webkit-scrollbar {
    height: 9px;
}
.freitext::-webkit-scrollbar-thumb {
    background-color: #A91D3A;;
    border-radius: 3px;
    cursor:auto;
}
.dark-mode .freitext::-webkit-scrollbar-thumb {
    background-color: #76ABAE;
    border-radius: 3px;
}
.freitext::-webkit-scrollbar-thumb:hover {
    background-color: #444444;
}
.freitext::-webkit-scrollbar-track {
    background: transparent;
}
.dark-mode .q-ebene-untertitel{
    color: #BE7B72;
}
.taxonomie {
    font-weight: bold;
    cursor: default;
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 5px 0;
    background: linear-gradient(45deg, #A91D3A, #FF6F61, #ff8b79);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite;
    transition: transform 0.4s ease;
    color: #EEEEEE;
} 
.dark-mode .taxonomie {
    background: linear-gradient(45deg, #A1C4FD, #76ABAE, #A1C4FD);
    background-size: 400% 400%;
    animation: holo-effect 10s linear infinite;
    color: #31363F;
}
.add-new-option {
    text-align: center;
    background-color: #A91D3A !important;
    color: #EEEEEE !important;
    font-weight: bold;
    font-size: 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    padding-bottom: 6px;
    padding-top: 0px;
    width: 100%;
    border-style: none;
}
.dark-mode .add-new-option{
    background-color: #76ABAE !important;
}
.add-new-option:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.button-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;
}
.copy-button {
    width: 25px;
    height: 30px;
    background-color: #A91D3A;
    color: #EEEEEE;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dark-mode .copy-button {
    background-color: #222831 !important;
    color: #ccc !important;
}
.copy-button:hover {
    background-color: #444444;
    transform: scale(1.15);
    transition: transform 0.3s ease;
}
.dark-mode .copy-button:hover {
    color: #222831 !important;
    background-color: #76ABAE !important;
}
.copy-all-button {
    align-items: center;
    justify-content: center;
    background-color: #A91D3A;
    color: #EEEEEE;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 24px;
    transition: transform 0.3s ease;
    display: flex;
    position: fixed;
    top: 65px;
    right: 20px;
    width: 44.4px; 
    height: 34px;
    z-index: 1000;
}
.dark-mode .copy-all-button {
    background-color: #EEEEEE;
    color: #222831;
}
.copy-all-button:hover {
    background-color: #444444;
    transform: scale(1.2);
    transition: transform 0.3s ease;
}
.dark-mode .copy-all-button:hover {
    background-color: #76ABAE;
    color: #EEEEEE;
}
.copy-all-button.copied {
    background-color: #45a049 !important;
    color: white;
}
.copy-button.copied {
    background-color: #45a049 !important;
    color: white;
}
.delete-button {
    width: 25px;
    height: 30px;
    background-color: #A91D3A;
    color: #EEEEEE;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dark-mode .delete-button {
    background-color: #222831 !important;
    color: #ccc !important;
}
.delete-button:hover {
    background-color: #444444;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}
.dark-mode .delete-button:hover {
    color: #222831 !important;
    background-color: #76ABAE !important;
}
.content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-container {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}
.add-container:hover {
    background-color: #A91D3A;
    color: #EEEEEE !important;
}
.dark-mode .add-container:hover {
    background-color: #76ABAE;
}
.add-icon {
    color: #A91D3A;
    font-size: 24px;
}
.dark-mode .add-icon {
    color: #76ABAE;
}
.add-container:hover .add-icon {
    color: #EEEEEE;
}
.dark-mode .add-container:hover .add-icon {
    color: #EEEEEE;
}
.custom-dropdown .dropdown-item-content {
    flex-grow: 1;
    cursor: pointer;
}
.custom-dropdown .dropdown-button {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #EEEEEE;
    color: #151515 !important;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 45px;
}
.dropdown-button {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #EEEEEE;
    color: #151515 !important;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.dropdown-button:hover {
    background-color: #EEEEEE;
    color: #151515;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.custom-dropdown {
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    max-width: 350px;
    max-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
    width: auto;
    max-height: 22vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: #000000;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    padding-top: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #EEEEEE;
    z-index: 1000;
}
.dark-mode .dropdown-menu {
    background-color: #31363F;
}
.dropdown-menu.left {
    right: 0;
}
.dropdown-menu.right {
    left: 0;
}
.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #151515;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.dark-mode .dropdown-item{
    color: #EEEEEE;
}
.dropdown-item:hover {
    background-color: #C73659;
    color: #EEEEEE;
}
.dark-mode .dropdown-item:hover {
    background-color: #76ABAE;
    color: #151515;
}
.edit-option,
.delete-option {
    margin-top: -5px;
    padding-right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    color: #aaa;
    transition: color 0.3s ease;
}
.edit-option:hover,
.delete-option:hover {
    background: none;
    color: #151515;
    transform: scale(1.3);
    transition: transform 0.3s ease;
}
.dropdown-item:hover .edit-option,
.dropdown-item:hover .delete-option {
    color: #EEEEEE;
}
.dropdown-item:hover .edit-option:hover,
.dropdown-item:hover .delete-option:hover {
    color: #151515;
}
.universal-alert {
    position: fixed;
    width: 35%;
    min-width: 350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #151515;
    background: #A91D3A;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    pointer-events: auto;
    border-radius: 8px;
}
.dark-mode .universal-alert {
    background-color: #31363F !important;
    color: #ccc;
}
.universal-alert input {
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    border-radius: 4px;
}
.universal-button {
    width: 25%;
    min-width: 100px;
    display: block;
    margin: auto;
    margin-top: 20px;
    font-size: 20px;
    border: none;
    border-radius: 4px;
    background-color: #EEEEEE;
    color: #444444;
    transition: transform 0.3s ease;
}
.dark-mode .universal-button {
    background-color: #444444;
    color: #76ABAE;
}
.universal-button:hover {
    background-color: #444444;
    color: #EEEEEE;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}
.dark-mode .universal-button:hover {
    background-color: #76ABAE;
    color: #444444;
}
.universal-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.universal-button.disabled {
    width: 30%;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}
.warning-icon {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-size: 75px;
    color: #ff9800;
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
}
.error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.error-window {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 80%;
    width: 400px;
}
.error-icon {
    font-size: 3rem;
    color: #f44336;
    margin-bottom: 1rem;
}
.error-window h2 {
    margin-bottom: 1rem;
    color: #333;
}
.error-window p {
    margin-bottom: 1.5rem;
    color: #666;
}
.error-window button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}
.error-window button:hover {
    background-color: #d32f2f;
}

.Lizenz {
    margin-left: 5.8%;
  }
